import React, { useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FaShareAlt } from 'react-icons/fa';
import { MdInsertLink } from "react-icons/md";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  RedditIcon,
  RedditShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  XIcon
} from 'react-share';
import Modal from '../Modal';
import { CopyToClipboardButton, ModalContentContainer, ShareButtonContainer, ShareButtonStyled, ShareContainer } from './styles';

interface ShareButtonProps {
  url: string;
}

const ShareButton: React.FC<ShareButtonProps> = ({ url }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [shortUrl, setShortUrl] = useState('');

  useEffect(() => {
    const shortAndSetUrl = async () => {
      try {
        if (!isModalOpen) {
          setShortUrl('');
          return;
        }
        const response = await fetch(`https://abre.ai/_/generate`, {
          method: "POST",
          mode: "cors",
          cache: "no-cache",
          credentials: "same-origin",
          headers: {
            "Content-Type": "application/json",
          },
          referrerPolicy: "no-referrer",
          body: JSON.stringify({
            url_translation: {
              url
            }
          }),
        });
        if (!response.ok) {
          throw new Error('Erro ao encurtar url');
        }
        const responseJson = await response.json();
        setShortUrl(responseJson?.data?.attributes?.shortenedUrl || url)
      } catch (error) {
        setShortUrl(url)
      }
    };
    if (document) {
      const el = document.getElementById("modal-content");
      el && el.scrollIntoView();
    }
    // shortAndSetUrl();
  }, [url, isModalOpen]);

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  return (
    <ShareButtonContainer>
      <ShareButtonStyled onClick={toggleModal}>
        <FaShareAlt color='black' />
      </ShareButtonStyled>
      {isModalOpen && (
        <Modal onClose={toggleModal} title='Compartilhe este link'>
          <ModalContentContainer>
            <FacebookShareButton url={window.location.href}>
              <ShareContainer>
                <div className='part-1'>
                  <FacebookIcon></FacebookIcon>
                  <span>Compartilhar no Facebook</span>   
                </div>
                <svg viewBox="0 0 16 16" enableBackground="new 0 0 24 24" className="sc-gKsewC iPWGYb"><path fillRule="evenodd" clipRule="evenodd" d="M4.70714 1.29297L5.06069 1.64652L11.0607 7.64652V8.35363L5.06069 14.3536L4.70714 14.7072L4.00003 14.0001L4.35358 13.6465L10 8.00008L4.35358 2.35363L4.00003 2.00008L4.70714 1.29297Z" fill="black"></path></svg>
              </ShareContainer>
            </FacebookShareButton>
            <TwitterShareButton url={window.location.href} title={"Confira esta página!"}>
              <ShareContainer>
                <div className='part-1'>
                  <XIcon></XIcon>
                  <span>Compartilhar no X</span>  
                </div>              
                <svg viewBox="0 0 16 16" enableBackground="new 0 0 24 24" className="sc-gKsewC iPWGYb"><path fillRule="evenodd" clipRule="evenodd" d="M4.70714 1.29297L5.06069 1.64652L11.0607 7.64652V8.35363L5.06069 14.3536L4.70714 14.7072L4.00003 14.0001L4.35358 13.6465L10 8.00008L4.35358 2.35363L4.00003 2.00008L4.70714 1.29297Z" fill="black"></path></svg>
              </ShareContainer>
            </TwitterShareButton>
            <LinkedinShareButton url={window.location.href} title={"Confira esta página!"}>
              <ShareContainer>
                <div className='part-1'>
                  <LinkedinIcon></LinkedinIcon>
                  <span>Compartilhar no LinkedIn</span>  
                </div>              
                <svg viewBox="0 0 16 16" enableBackground="new 0 0 24 24" className="sc-gKsewC iPWGYb"><path fillRule="evenodd" clipRule="evenodd" d="M4.70714 1.29297L5.06069 1.64652L11.0607 7.64652V8.35363L5.06069 14.3536L4.70714 14.7072L4.00003 14.0001L4.35358 13.6465L10 8.00008L4.35358 2.35363L4.00003 2.00008L4.70714 1.29297Z" fill="black"></path></svg>
              </ShareContainer>
            </LinkedinShareButton>
            <WhatsappShareButton url={window.location.href} title={"Confira esta página!"}>
              <ShareContainer>
                <div className='part-1'>
                  <WhatsappIcon></WhatsappIcon>
                  <span>Compartilhar no WhatsApp</span>  
                </div>              
                <svg viewBox="0 0 16 16" enableBackground="new 0 0 24 24" className="sc-gKsewC iPWGYb"><path fillRule="evenodd" clipRule="evenodd" d="M4.70714 1.29297L5.06069 1.64652L11.0607 7.64652V8.35363L5.06069 14.3536L4.70714 14.7072L4.00003 14.0001L4.35358 13.6465L10 8.00008L4.35358 2.35363L4.00003 2.00008L4.70714 1.29297Z" fill="black"></path></svg>
              </ShareContainer>
            </WhatsappShareButton>
            <TelegramShareButton url={window.location.href} title={"Confira esta página!"}>
              <ShareContainer>
                <div className='part-1'>
                  <TelegramIcon></TelegramIcon>
                  <span>Compartilhar no Telegram</span>  
                </div>              
                <svg viewBox="0 0 16 16" enableBackground="new 0 0 24 24" className="sc-gKsewC iPWGYb"><path fillRule="evenodd" clipRule="evenodd" d="M4.70714 1.29297L5.06069 1.64652L11.0607 7.64652V8.35363L5.06069 14.3536L4.70714 14.7072L4.00003 14.0001L4.35358 13.6465L10 8.00008L4.35358 2.35363L4.00003 2.00008L4.70714 1.29297Z" fill="black"></path></svg>
              </ShareContainer>
            </TelegramShareButton>
            <RedditShareButton url={window.location.href} title={"Confira esta página!"}>
              <ShareContainer>
                <div className='part-1'>
                  <RedditIcon></RedditIcon>
                  <span>Compartilhar no Reddit</span>  
                </div>              
                <svg viewBox="0 0 16 16" enableBackground="new 0 0 24 24" className="sc-gKsewC iPWGYb"><path fillRule="evenodd" clipRule="evenodd" d="M4.70714 1.29297L5.06069 1.64652L11.0607 7.64652V8.35363L5.06069 14.3536L4.70714 14.7072L4.00003 14.0001L4.35358 13.6465L10 8.00008L4.35358 2.35363L4.00003 2.00008L4.70714 1.29297Z" fill="black"></path></svg>
              </ShareContainer>
            </RedditShareButton>
            <EmailShareButton url={window.location.href} subject={"Confira esta página!"} body="Achei isso interessante: ">
              <ShareContainer>
                <div className='part-1'>
                  <EmailIcon></EmailIcon>
                  <span>Compartilhar por E-mail</span>
                </div>  
                <svg viewBox="0 0 16 16" enableBackground="new 0 0 24 24" className="sc-gKsewC iPWGYb"><path fillRule="evenodd" clipRule="evenodd" d="M4.70714 1.29297L5.06069 1.64652L11.0607 7.64652V8.35363L5.06069 14.3536L4.70714 14.7072L4.00003 14.0001L4.35358 13.6465L10 8.00008L4.35358 2.35363L4.00003 2.00008L4.70714 1.29297Z" fill="black"></path></svg>
              </ShareContainer>
            </EmailShareButton>
            {shortUrl && (
              <CopyToClipboardButton>
                <CopyToClipboard text={shortUrl} onCopy={() => alert('Link copiado!')}>
                  <ShareContainer>
                    <div className='part-1'>
                      <MdInsertLink color='black'></MdInsertLink>
                      <span>{shortUrl}</span>
                    </div>     
                    <label>Copiar</label>    
                  </ShareContainer>
                </CopyToClipboard>
              </CopyToClipboardButton>
            )}
          </ModalContentContainer>
        </Modal>
      )}
    </ShareButtonContainer>
  );
};

export default ShareButton;
