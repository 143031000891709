import React from 'react';
import { ChildrenContainer, CloseButton, Header, ModalBackdrop, ModalContent } from './styles';

interface ModalProps {
  onClose: () => void;
  children: React.ReactNode 
  title?: string;
}

const Modal: React.FC<ModalProps> = ({ onClose, children, title }) => {
  return (
    <ModalBackdrop>
      <ModalContent id="modal-content">
        <Header>
          {title && <h1>{title}</h1>}
          <CloseButton onClick={onClose}>×</CloseButton>
        </Header>
        <ChildrenContainer>
          {children}
        </ChildrenContainer>       
        
      </ModalContent>
    </ModalBackdrop>
  );
};

export default Modal;