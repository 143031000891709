import styled from 'styled-components';

export const ContactContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 48px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 8px;

    width: 100%;
    
    input, textarea {
      width: 100%;
      padding: 8px;
    }

    button {
      height: 32px;
      background-color: #333;
      color: white;
      border: unset;
      cursor: pointer;

      &:hover {
        background-color: #333;
        opacity: 0.8;
      }
    }
  }
`;

export const PersonalInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  min-width: 292px;
  gap: 24px;
`;

export const Address = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  min-width: 292px;
  
  h2 {
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 8px;
  }
`;

export const Phone = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  min-width: 292px;
  
  h2 {
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 8px;
  }
`;

export const Email = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  min-width: 292px;
  
  h2 {
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 8px;
  }
`;

export const CommercialTemplateLinksContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  width: 100%;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }

  img {
    height: 100%; /* Mantém a altura da div */
    object-fit: cover; /* Mantém a proporção da imagem e cobre completamente a área */
    width: 70px;
  }

  a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    overflow-wrap: break-word;
    word-break: break-word;
    text-align: center;

    span {
      width: 74%;
    }
  }
`;

export const CommercialTemplateLinkItem = styled.div`
  flex-direction: row;
  align-items: flex-start;
  text-align: left;
  margin-left: 1rem;
  margin-right: 1rem;
  display: flex;
  height: 100%;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  padding: 2rem 0.5rem;
  text-align: center;
  box-shadow: var(--shd,0 1px 3px rgba(0,0,0,0.5));
  max-width: 324px;
  padding: 24px;
  border-radius: 24px;

  p {
    padding: 24px 0;
  }

  &:hover {
    background: rgb(243, 243, 241);
  }
`;

export const SelfEmployedTemplate1LinksContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  width: 100%;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }

  img {
    height: 100%; /* Mantém a altura da div */
    object-fit: cover; /* Mantém a proporção da imagem e cobre completamente a área */
    width: 70px;
  }

  a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    overflow-wrap: break-word;
    word-break: break-word;
    text-align: center;

    span {
      width: 74%;
    }
  }
`;

export const LinkItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border: 2px solid rgb(255, 255, 255);
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  box-shadow: rgb(0 3 77 / 24%) 0px 2px 8px 0px;
  transition: transform 0.25s cubic-bezier(0.08, 0.59, 0.29, 0.99) 0s, box-shadow 0.25s cubic-bezier(0.08, 0.59, 0.29, 0.99) 0s;
  border-radius: 8px;
  height: 60px;

  &:hover {
    background: rgb(243, 243, 241);
  }
`;

export const YoutubeEmbed = styled.iframe`
  width: 100%;
  height: 600px; 
  border: 0;
  box-shadow: rgb(0 3 77 / 24%) 0px 2px 8px 0px;

  @media (max-width: 767px) {
    height: 200px; 
  }
`;