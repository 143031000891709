import styled from 'styled-components';

interface WhatsAppBarProps {
  backgroundColor?: string,
  buttonColor?: string,
  hoverColor?: string;
  textColor?: string
}

export const WhatsAppBar = styled.div<WhatsAppBarProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 68px;
  background-color: ${(props) => props.backgroundColor || '#fff'};
  color: ${(props) => props.textColor || '#000'};

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    position: absolute;
    border-radius: 8px;
    padding: 8px;
    background-color: ${(props) => props.buttonColor || 'rgb(1, 230, 117)'};
    cursor: pointer;

    svg {
      width: 24px;
      height: 24px;
    }

    &:hover {
      background-color: ${(props) => props.hoverColor || 'rgb(1, 230, 117, 0.7)'}; ;
    }
  }
`;

export const ShareButtonContainer = styled.div`
  position: absolute;
  right: 0;
  top: 22px;

  svg {
    color: white !important;
    height: 24px !important;
    width: 24px !important;
  } 
`;


export const SocialsButtons = styled.div`
  display: flex;
  gap: 12px;

  svg {
    width: 32px;
    height: 32px;
  }
`;