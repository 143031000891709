/* eslint-disable array-callback-return */
import React from 'react';
import { FaFacebook, FaInstagram, FaTiktok, FaWhatsapp, FaYoutube } from "react-icons/fa";
import { replaceVars } from '../../../utils/replaceVars';
import AnimationRevealPage from '../../components/helpers/AnimationRevealPage';
import InstallPWA from '../../components/PWA/InstallPWA';
import SectionsFactory from '../../components/SectionsFactory';
import ShareButton from '../../components/ShareButton';
import { TemplateProps } from '../TemplateFactory';
import { Background, Header, HubContainer, ImageOverlay, ProfessionalDescription, Sections } from './styles';
import { ShareButtonContainer, SocialsButtons, WhatsAppBar } from '../Shared/styles';

const SelfEmployedTemplate1: React.FC<TemplateProps> = ({ data }: TemplateProps) => {
  if (!data) {
    return <p>Nenhum dado encontrado.</p>;
  }

  const { socialMedias, whatsAppBar, commercialPresentation, sections, colors, activePWA } = data.site;

  return (
    <AnimationRevealPage>
      <Background>
        <HubContainer fontFamily={colors.font.family} fontColor={colors.font.colorPrimary}>
          {activePWA && <InstallPWA />}
          <Header backgroundColor={colors.headerColor}>
            {socialMedias && socialMedias.isDisplaying && (
                <SocialsButtons>
                  {Object.entries(socialMedias).map(([key, value]) => {
                    if (key === 'isDisplaying') return;
                    return (
                      <a key={key} href={value as string} target='_blank' rel='noreferrer'>
                        {key === 'facebook' && <FaFacebook color={colors.socialIconsColors} />}
                        {key === 'instagram' && <FaInstagram color={colors.socialIconsColors} />}
                        {key === 'tiktok' && <FaTiktok color={colors.socialIconsColors} />}
                        {key === 'youtube' && <FaYoutube color={colors.socialIconsColors} />}
                      </a>
                    );
                  })}
                </SocialsButtons>
              ) 
            }
            <ShareButtonContainer>
              <ShareButton url={window.location.href.includes('localhost') ? `https://localhost.com${window.location.pathname}` : window.location.href}></ShareButton>
            </ShareButtonContainer>      
          </Header>
          {whatsAppBar && whatsAppBar.isDisplaying && (
            <WhatsAppBar 
              backgroundColor={colors.whatsAppBar.backgroundColor} 
              buttonColor={colors.whatsAppBar.buttonColor} 
              hoverColor={colors.whatsAppBar.hoverColor} 
              textColor={colors.whatsAppBar.textColor}
            >
              <a href={replaceVars(data, whatsAppBar.link)} target='_blank' rel='noreferrer'><FaWhatsapp />{data.site.whatsAppBar.label}</a>
            </WhatsAppBar>
          )}
          {commercialPresentation && commercialPresentation.isDisplaying && (
            <ProfessionalDescription 
              backgroundImage={commercialPresentation.backgroundImage} 
              backgroundColor={commercialPresentation.backgroundColor}
            >
              <img alt='' src={commercialPresentation.commercialPhotoOrLogo}></img>
              <h1>{commercialPresentation.commercialName}</h1>
              <h2>{commercialPresentation.title}</h2>
              <h3>{commercialPresentation.subtitle}</h3>
              <ImageOverlay />     
            </ProfessionalDescription> 
          )}
          <Sections evenColor={colors.evenSectionColor} oddColor={colors.oddSectionColor}>
            {sections.map(section => {
              return (
                <SectionsFactory key={section.type} section={section} json={data}></SectionsFactory>
              )
            })}
          </Sections>
        </HubContainer>
      </Background>
    </AnimationRevealPage>
  )
};

export default SelfEmployedTemplate1;
