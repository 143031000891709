import styled from 'styled-components';

export const ShareButtonContainer = styled.div`
  .react-share__ShareButton {
    background: none;
    border: none;
  }
`;

export const ShareButtonStyled = styled.button`
  margin-right: 16px;
  height: 32px;
  width: 32px;
  background: none;
  border: none;
  cursor: pointer;

  svg {
    height: 18px;
    width: 18px;   
  }

  transition: transform 0s cubic-bezier(0.23, 1, 0.32, 1) 0s;

  &:hover {
    transform: scale(1.075);
  }
`;

export const ModalContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  button {
    width: 100% !important;
  }
`;

export const ShareContainer = styled.div`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  color: rgb(10, 11, 13);
  width: 100%;
  height: 100%;
  justify-content: space-between;
  border-radius: 8px;
  padding: 16px;

  .part-1 {
    display: flex;
    gap: 16px;

    svg {
      height: 24px !important;
      width: 24px !important;   
    }
  }

  button {
    display: flex;
    margin-left: 16px;
    flex: 1 1 0%;
    width: 100%;
    height: 100%;
  }

  svg {
    width: 18px;
  }

  &:hover {
    background: rgb(243, 243, 241);
  }
`;

export const CopyToClipboardButton = styled.button`
  border: 1px solid rgb(224, 226, 217);
  margin: 0px;
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
  text-align: center;
  cursor: pointer;
  display: block;
  background: none;
  border-radius: 8px;
  height: 60px;
  width: 92%;
  appearance: none;
  box-sizing: border-box;
  vertical-align: middle;
  background: none;
  margin-top: 16px;

  .part-1 {
    display: flex;
    align-items: center;
  }

  svg {
    width: 32px;
    height: 32px;
  }
`
