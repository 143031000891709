import styled from 'styled-components';
import { WhatsAppBar, ShareButtonContainer, SocialsButtons } from '../Shared/styles';

export const Background = styled.div`
  background-color: #fafafa;
`;

interface HubContainerProps {
  fontFamily?: string;
  fontColor?: string;
}

export const HubContainer = styled.div<HubContainerProps>`
  min-height: 100vh;
  overflow: hidden;
  padding: 0 2rem;
  padding-bottom: 0;
  font-weight: 600;
  font-family: ${(props) => props.fontFamily || "'Arial', sans-serif"};
  color: ${(props) => props.fontColor || "black"};

  .pwa {
    margin-left: -2rem;
    margin-right: -2rem;
    transform: translate(0, 5.1rem); 
  }
`;

interface FirstSectionProps {
  headerBackgroundColor: string;
}

export const CustomWhatsAppBar = styled(WhatsAppBar)`
  margin-left: -2rem;
  margin-right: -2rem;
  transform: translate(0, 5.1rem);
`;

export const CustomShareButtonContainer = styled(ShareButtonContainer)<HubContainerProps>`
  right: 48px;
  svg {
    color: ${(props) => props.fontColor + ' !important' || "black !important"};
    height: 24px !important;
    width: 24px !important;
  } 
`;

export const CustomSocialButtons = styled(SocialsButtons)`
  margin-left: -2rem;
  margin-right: -2rem;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    transform: translate(0, 11rem);
    margin-top: 92px;
  }
  
  a {
    transition: transform 0s cubic-bezier(0.23, 1, 0.32, 1) 0s;

    &:hover {
      transform: scale(1.075);
    }
  }
`;

export const FirstSection = styled.div<FirstSectionProps>`
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: #e2e8f0;

  .desktop-menu {
    visibility: visible;
  }
  .mobile-menu {
    visibility: hidden;
  }

  @media (max-width: 990px) {
    .desktop-menu {
      visibility: hidden;
    }
    .mobile-menu {
      visibility: visible;
    }
  }
  @media (max-width: 990px) {
    .hamburger {
      display: block; /* Mostra o botão hambúrguer */
      width: 72px;
      height: 72px;
      background: none;
      border: 0;

      &:hover {
        cursor: pointer;
      }
    }
    
    .menu {
      display: none; /* Esconde o menu por padrão */
      position: fixed;
      top: 0; right: 0;
      width: 250px; /* Largura da gaveta */
      height: 100%; /* Altura total da tela */
      background-color: #fff; /* Cor de fundo da gaveta */
      /* Mais estilos para a gaveta aqui */
      transform: translateX(100%); /* Esconde a gaveta fora da tela */
      transition: transform 0.3s ease-in-out; /* Animação suave */
    }
    
    .menu.active {
      transform: translateX(0); /* Mostra a gaveta */
    }
  }

  header {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    max-width: 1280px;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    max-width: none;
    background-color: ${(props) => props.headerBackgroundColor || '#fff'};
    height: 84px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    z-index: 1000;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
    text-align: center;

    nav {   
      display: flex;
      flex: 1 1 0%;
      -webkit-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
      justify-content: space-between;
      margin: 0 48px;

      @media (max-width: 990px) {
        margin: 0;
      }

      .options {
        display: flex;
        gap: 48px;
      }
    }
  }
`;

interface HeroProps {
  fontPrimaryColor?: string;
  fontSecondaryColor?: string;
  fontTertiaryColor?: string;
}

export const Hero = styled.div<HeroProps>`
  padding-top: 6rem;
  padding-bottom: 6rem;

  @media (max-width: 768px) {
    padding-top: 0;
    padding-bottom: 0;
  }

  .two-column-layout {
    flex-direction: row;
    padding-top: 4rem;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    max-width: 1440px;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 1300px) {
      flex-direction: column;
      gap: 124px;
    }

    @media (max-width: 768px) {
      gap: 84px;
      transform: translate(0, 5rem);
    }

    .column-1 {
      margin-right: 0;
      max-width: 672px;

      h2 {
        font-size: 4rem;
        text-align: left;
        line-height: 1.375;
        color: ${(props) => props.fontPrimaryColor || "rgb(60 13 153)"};

        @media (max-width: 768px) {
          font-size: 3rem;
        }
      }

      p {
        font-size: 1rem;margin-top: 1rem;
        max-width: 32rem;
        color: ${(props) => props.fontSecondaryColor || "rgb(74 85 104)"};
        @media (max-width: 768px) {
          font-size: 1rem;
        }
      }
    }

    .column-2 {
      margin-right: 0;
      margin-top: 0;
      margin-left: 8rem;
      position: relative;
      z-index: 40;

      @media (max-width: 768px) {
        margin-left: 0;
      }

      .image-container {
        transform: translate(-6rem, -4rem);

        @media (max-width: 768px) {
          position: relative;
          z-index: 20;
          width: 24rem;
          max-width: 100%;
          border-top-left-radius: 0.25rem;
          border-top-right-radius: 0.25rem;
          transform: none;
        }

        img {
          position: relative;
          z-index: 20;
          width: 24rem;
          max-width: 100%;
          border-radius: 0.25rem;
        }

        .text {
          position: absolute;
          padding-left: 2.5rem;
          padding-right: 2.5rem;
          padding-top: 2rem;
          padding-bottom: 2rem;
          background-color: ${(props) => props.fontPrimaryColor || "rgb(60 13 153)"};
          transform: translate(-9rem, -10.4rem);
          z-index: 100;
          width: 100%;

          @media (max-width: 764px) {
            transform: translate(0rem, -3.4rem);
          }

          svg {
            height: 48px;
            width: 48px;
            transform: translate(3.5rem, 3.5rem);
            color: rgb(245, 101, 101);
            position: absolute;
            top: 0;
            transform: translate(-22.5rem, -1.4rem);
            opacity: 1;

            @media (max-width: 610px) {
              display: none;
            }
          }

          h1, blockquote {
            margin: 0;
            color: ${(props) => props.fontTertiaryColor || "#fff"};
          }
        }

        svg {
          pointer-events: none;
          position: absolute;
          right: 0;
          bottom: 0;
          z-index: 10;
          height: 8rem;
          width: 8rem;
          color: rgb(26 32 44);
          opacity: 0.25;
          transform: translate(3.5rem, 3.5rem);

          @media (max-width: 768px) {
            display: none;
          }
        }
      }

      .image-background-effect {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-radius: 0.25rem;
        background-color: rgb(226 232 240);
        margin-bottom: -2rem;
        z-index: -1;

        @media (max-width: 768px) {
          display: none;
        }
      }
    }
  }
`;

interface SectionsProps {
  oddColor?: string;
  evenColor?: string;
  fontSecondaryColor?: string;
  fontTertiaryColor?: string;
}

export const Sections = styled.div<SectionsProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: -2rem;
  margin-right: -2rem;

  @media (max-width: 768px) {
    transform: translate(0, 10rem);
    margin-bottom: 10rem;
  }

  section:nth-child(odd) {
    background-color: ${(props) => props.oddColor || '#fff'};
    color: ${(props) => props.fontSecondaryColor || "#000"};
  }

  section:nth-child(even) {
    background-color: ${(props) => props.evenColor || '#eee'};
    color: ${(props) => props.fontTertiaryColor || "#000"};
  }

  section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;   
    padding: 40px;
    width: 100%;

    .section-content {
      display: flex;
      justify-content: center;
      align-items: center;   
      flex-direction: column;
      gap: 40px;
      max-width: 800px;

      h1 {
        font-size: 32px;
        font-weight: 700;
        text-align: center;
      }

      h2 {
        text-align: center;
        font-size: 24px;
        font-weight: 700;
      }

      p {
        font-size: 16px;
        font-weight: 400;  
      }
    }
  }
`;
