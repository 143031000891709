/* eslint-disable array-callback-return */
import React from 'react';
import AnimationRevealPage from '../../components/helpers/AnimationRevealPage';
import { TemplateProps } from '../TemplateFactory';
import { ReactComponent as DotsSvg } from './svgs/dots.svg';
import { ReactComponent as QuotesSvg } from './svgs/quotes.svg';
import { HubContainer, FirstSection, Hero, Sections, CustomWhatsAppBar, CustomShareButtonContainer, CustomSocialButtons } from './styles';
import SectionsFactory from '../../components/SectionsFactory';
import { FaFacebook, FaInstagram, FaTiktok, FaWhatsapp, FaYoutube } from 'react-icons/fa';
import { replaceVars } from '../../../utils/replaceVars';
import ShareButton from '../../components/ShareButton';
import HamburgerMenu from '../Shared/HamburgerMenu';
import InstallPWA from '../../components/PWA/InstallPWA';

const CommercialTemplate: React.FC<TemplateProps> = ({ data }: TemplateProps) => {
  if (!data) {
    return <p>Nenhum dado encontrado.</p>;
  }

  const { socialMedias, whatsAppBar, commercialPresentation, sections, colors, activePWA } = data.site;

  return (
    <AnimationRevealPage>
      <HubContainer id='inicio' fontFamily={colors.font.family} fontColor={colors.headerFontColor}>
        <FirstSection headerBackgroundColor={colors.headerColor}>
          {data.site.useNavHeader && (
            <>
              <header className={`desktop-menu`}>
                <nav>
                  <a href={`${window.location.pathname}#inicio`}>
                    <img alt='' height={72} src={commercialPresentation.commercialPhotoOrLogo}></img>
                  </a>
                  <div className='options'>
                    <a href={`${window.location.pathname}#inicio`}>Inicio</a>    
                    {data.site.sections.map((s) => {
                      return <a href={`${window.location.pathname}#${s.anchor}`}>{s.title}</a>     
                    })}
                  </div>
                  <div></div>
                </nav>
                <CustomShareButtonContainer fontColor={colors.headerFontColor}>
                  <ShareButton url={window.location.href.includes('localhost') ? `https://localhost.com${window.location.pathname}` : window.location.href}></ShareButton>
                </CustomShareButtonContainer> 
              </header>       
              <header className={`mobile-menu`}>
                <a href='/'>
                  <img alt='' height={72} src={commercialPresentation.commercialPhotoOrLogo}></img>
                </a>
              
                <HamburgerMenu sections={data.site.sections} />
              </header>     
            </>
          )} 
          {activePWA && <InstallPWA />}
          {whatsAppBar && whatsAppBar.isDisplaying && (
            <CustomWhatsAppBar 
              backgroundColor={colors.whatsAppBar.backgroundColor} 
              buttonColor={colors.whatsAppBar.buttonColor} 
              hoverColor={colors.whatsAppBar.hoverColor} 
              textColor={colors.whatsAppBar.textColor}
            >
              <a href={replaceVars(data, whatsAppBar.link)} target='_blank' rel='noreferrer'><FaWhatsapp />{data.site.whatsAppBar.label}</a>
            </CustomWhatsAppBar>
          )}
          {commercialPresentation.isDisplaying &&
            <Hero           
              fontPrimaryColor={colors.font.colorPrimary} 
              fontSecondaryColor={colors.font.colorSecundary}
              fontTertiaryColor={colors.font.colorTertiary}
            >
              <div className='two-column-layout'>
                <div className='column-1'>
                  <h2>{commercialPresentation.title}</h2>
                  <p>{commercialPresentation.subtitle}</p>
                </div>
                <div className='column-2'>
                  <div className='image-container'>
                    <img alt='' src={commercialPresentation.quotePhoto} />
                    <DotsSvg></DotsSvg>
                    {commercialPresentation.quote && 
                      (
                        <div className='text'>
                          <QuotesSvg></QuotesSvg>
                          <blockquote>{commercialPresentation.quote}</blockquote>
                        </div>
                      )}
                  </div>
                  <div className='image-background-effect'></div>
                </div>
              </div>
            </Hero>
          }
          {socialMedias && socialMedias.isDisplaying && (
              <CustomSocialButtons>
                <label>Redes Sociais:</label>
                {Object.entries(socialMedias).map(([key, value]) => {
                  if (key === 'isDisplaying') return;
                  return (
                    <a key={key} href={value as string} target='_blank' rel='noreferrer'>
                      {key === 'facebook' && <FaFacebook color={colors.socialIconsColors} />}
                      {key === 'instagram' && <FaInstagram color={colors.socialIconsColors} />}
                      {key === 'tiktok' && <FaTiktok color={colors.socialIconsColors} />}
                      {key === 'youtube' && <FaYoutube color={colors.socialIconsColors} />}
                    </a>  
                  );
                })}
              </CustomSocialButtons>
            ) 
          }
          <Sections 
            evenColor={colors.evenSectionColor} 
            oddColor={colors.oddSectionColor}
            fontSecondaryColor={colors.font.colorSecundary}
            fontTertiaryColor={colors.font.colorTertiary}
          >
            {sections.map(section => {
              return (
                <SectionsFactory key={section.type} section={section} json={data}></SectionsFactory>
              )
            })}
          </Sections>
        </FirstSection>
      </HubContainer>
    </AnimationRevealPage>
  )
};

export default CommercialTemplate;
