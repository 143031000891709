import styled from 'styled-components';

export const InstallPWAContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px auto;
`;

export const InstallPWAButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  background-color: rgb(1, 230, 117);
  cursor: pointer;
  border: 0;
  width: 100%;

  &:hover {
    background-color: rgb(1, 230, 117, 0.5);
  }
`;
