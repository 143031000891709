import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { JsonPath } from '../../../types/json-path';
import TemplateFactory from '../../templates/TemplateFactory';

const Hub: React.FC = () => {
  const { hubPath } = useParams();
  const [data, setData] = useState<JsonPath | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    new Error()
    async function fetchData() {
      try {
        const response = await fetch(`${process.env.REACT_APP_ENV !== undefined ? `https://${window.location.host}` : 'http://localhost:3000'}/paths/${hubPath}.json`, {
          method: "GET",
          mode: "cors",
          cache: "no-cache",
          credentials: "same-origin",
          headers: {
            "Content-Type": "application/json",
          },
          referrerPolicy: "no-referrer"
        });
        if (!response.ok) {
          throw new Error('Erro ao buscar dados da pagina');
        }
        const responseText = await response.text();
        if (responseText.includes('<html')) throw new Error('Erro ao buscar dados da pagina');
        const data = JSON.parse(responseText);
        setData(data);
        setLoading(false);
      } catch (error) {
        setError(error as any);
        setLoading(false);
      }
    }

    fetchData();
  }, [hubPath]);

  if (loading) {
    return <p>Carregando...</p>;
  }

  if (error) {
    return <p>Erro: {(error as any).message}</p>;
  }

  if (!data) {
    return <p>Nenhum dado encontrado.</p>;
  }

  return (
    <TemplateFactory data={data} />
  )
};

export default Hub;
