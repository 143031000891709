import { useInView as useInViewFromFramer } from 'framer-motion';
import { RefObject, useRef } from 'react';

// Define the return type of the hook: [RefObject<HTMLDivElement>, boolean]
function useInView({ once = true, margin = "-30px 0px 0px 0px" } = {}): [RefObject<HTMLDivElement>, boolean] {
  const ref = useRef<HTMLDivElement>(null); // Specify the element type for useRef
  const isInView = useInViewFromFramer(ref, {
    once: once,
    margin: margin,
  });

  // Since the useInView from 'framer-motion' might not directly return a boolean (depending on its version or behavior),
  // ensure to handle or typecast accordingly. The current setup assumes it returns a boolean.
  
  return [ref, !!isInView]; // Ensure the second element is treated as a boolean
}

export default useInView;
