// HamburgerMenu.tsx
import React, { useState } from 'react';
import styled from 'styled-components';
import { ContactType, LinksHubSectionType, TextSectionType } from '../../../../types/json-path';

// Styled components para o menu e itens
const MenuIcon = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  margin-right: 24px;
  span {
    height: 2px;
    width: 25px;
    background: #333;
    margin-bottom: 4px;
    border-radius: 2px;
    transition: all 0.3s ease-in-out;
  }
`;

const MenuLinks = styled.nav<{ isOpen: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 250px;
  background-color: #fff;
  box-shadow: -2px 0px 5px rgba(0, 0, 0, 0.5);
  transform: ${({ isOpen }) => (isOpen ? 'translateX(0)' : 'translateX(100%)')};
  transition: transform 0.3s ease-in-out;
  z-index: 10;
  padding: 2rem;

  a {
    padding: 1rem 0;
    color: #333;
    text-decoration: none;
    transition: color 0.3s ease-in-out;

    &:hover {
      color: #007bff;
    }
  }
`;

const Overlay = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  z-index: 5;
`;

interface HamburgerMenuProps {
  sections: (LinksHubSectionType | TextSectionType | ContactType)[]
}

const HamburgerMenu: React.FC<HamburgerMenuProps> = ({ sections }: HamburgerMenuProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <MenuIcon onClick={toggleMenu}>
        <span />
        <span />
        <span />
      </MenuIcon>
      <Overlay isOpen={isOpen} onClick={toggleMenu} />
      <MenuLinks isOpen={isOpen}>
        <a href="#inicio" onClick={toggleMenu}>Início</a>
        {sections.map((s) => {
          return <a onClick={toggleMenu} href={`${window.location.pathname}#${s.anchor}`}>{s.title}</a>     
        })}
      </MenuLinks>
    </>
  );
};

export default HamburgerMenu;
