// src/AppRouter.tsx
import React, { useEffect } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Hub from '../../presentation/screens/Hub';

function RedirectToExternalHTML() {
  useEffect(() => {
    // Define a URL para o arquivo HTML
    const externalURL = '/site/index.html';
    // Redireciona para o arquivo HTML
    window.location.href = externalURL;
  }, []);

  // Renderiza algo enquanto o redirecionamento não é completado (opcional)
  return <div></div>;
}

const AppRouter: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<RedirectToExternalHTML />} />
        <Route path="/:hubPath" element={<Hub />} />
      </Routes>
    </Router>
  );
};

export default AppRouter;
