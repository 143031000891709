import React from 'react';
import { JsonPath } from '../../../types/json-path';
import CommercialTemplate from '../CommercialTemplate';
import SelfEmployedTemplate1 from '../SelfEmployedTemplate1';

export interface TemplateProps {
  data: JsonPath;
}

const TemplateFactory: React.FC<TemplateProps> = ({ data }) => {
  console.log(data.template);
  const renderTemplate = () => {
    switch (data.template) {
      case 'SelfEmployedTemplate1': 
        return (
          <SelfEmployedTemplate1 data={data} />
        )
      case 'CommercialTemplate': 
        return (
          <CommercialTemplate data={data} />
        )
      default:
        return (<div>Template não encontrado</div>)
    }
  }

  return renderTemplate();
};

export default TemplateFactory;