import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  /* Reset padrão */
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  /* Estilo adicional para o body ou html, se necessário */
  body, html {
    font-family: 'Arial', sans-serif;
    font-size: 16px;
    line-height: 1.5;
    scroll-behavior: smooth;
  }

  /* Adicione mais estilos globais conforme necessário */

  /* Exemplo de estilo para links */
  a {
    text-decoration: none;
    color: inherit;
  }

  /* Exemplo de estilo para listas */
  ul, ol {
    list-style: none;
  }

  /* Adicione estilos adicionais conforme necessário */
`;

export default GlobalStyles;
