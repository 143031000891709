import { motion } from "framer-motion";
import React from 'react'; // Import React for TypeScript types
import useInView from "../useInView";
import { StyledDiv } from "./styles";

// Define types for the AnimationReveal component props
interface AnimationRevealProps {
  disabled?: boolean;
  children: React.ReactNode;
}

const AnimationReveal: React.FC<AnimationRevealProps> = ({ disabled, children }) => {
  if (disabled) {
    return <>{children}</>;
  }

  if (!Array.isArray(children)) children = [children];

  const directions = ["left", "right"];
  const childrenWithAnimation = (children as [React.ReactNode]).map((child, i) => (
    <AnimatedSlideInComponent key={i} direction={directions[i % directions.length]}>
      {child}
    </AnimatedSlideInComponent>
  ));

  return <>{childrenWithAnimation}</>;
};

// Define types for the AnimatedSlideInComponent props
interface AnimatedSlideInComponentProps {
  direction?: string;
  offset?: number;
  children: React.ReactNode;
}

const AnimatedSlideInComponent: React.FC<AnimatedSlideInComponentProps> = ({ direction = "left", offset = 30, children }) => {
  const [ref, inView] = useInView({ margin: `-${offset}px 0px 0px 0px` });

  const x = { target: "0%", initial: "" };

  if (direction === "left") x.initial = "-150%";
  else x.initial = "150%";

  return (
    <div ref={ref}>
      <motion.section
        initial={{ x: x.initial }}
        animate={{ 
          x: inView ? x.target : x.initial,
          transitionEnd: {
            x: inView ? 0 : undefined
          }
        }}
        transition={{ type: "spring", damping: 19 }}
      >
        {children}
      </motion.section>
    </div>
  );
};

// Default export component with typed props
// eslint-disable-next-line import/no-anonymous-default-export
export default (props: AnimationRevealProps) => (
  <StyledDiv className="App">
    <AnimationReveal {...props} />
  </StyledDiv>
);
