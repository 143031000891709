import React, { useEffect, useState } from 'react';
import { InstallPWAButton, InstallPWAContainer } from './styles';

function urlBase64ToUint8Array(base64String: string) {
  const padding = '='.repeat((4 - base64String.length % 4) % 4);
  const base64 = (base64String + padding)
    .replace(/\-/g, '+')
    .replace(/_/g, '/');
  
  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);
  
  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}
const InstallPWA: React.FC = () => {
  // Define o tipo para o estado que armazenará o evento
  const [promptInstall, setPromptInstall] = useState<BeforeInstallPromptEvent | null>(null);

  useEffect(() => {
    const handleBeforeInstallPrompt = (e: BeforeInstallPromptEvent) => {
      // Impede que o mini-infobar apareça em dispositivos móveis
      e.preventDefault();
      // Salva o evento para que possa ser acionado mais tarde
      setPromptInstall(e);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt as EventListener);

    return () => window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt as EventListener);
  }, []);

  const requestNotificationPermission = async (): Promise<string> => {
    if ('serviceWorker' in navigator && 'PushManager' in window) {
      // Registra o service worker
      try {
        const registration = await navigator.serviceWorker.register('/service-worker.js');
        const permission = await Notification.requestPermission();
        if (permission === 'granted') {
          console.log('Permissão de notificação concedida.');

          // Converte a chave pública VAPID para o formato esperado
          const publicKey = 'SUA_PUBLIC_KEY_VAPID'; // Substitua pela sua chave pública VAPID real
          const convertedVapidKey = urlBase64ToUint8Array(publicKey);
          
          // Subscreve para push
          try {
            console.log('aaaa');
            const subscription = await registration.pushManager.subscribe({
              userVisibleOnly: true,
              applicationServerKey: convertedVapidKey
            });
            console.log(subscription);
            console.log('Usuário inscrito para push:', subscription);     
          }
          catch (err) {
            console.warn('Falha ao inscrever o usuário:', err);
          }
          return 'granted';
        } else {
          console.log('Permissão de notificação não concedida.');
          return 'denied';
        }
      } catch (err) {
        console.log('Falha ao registrar o service worker:', err);
        return 'default';
      }
    }
    return 'default';
  }

  const handleInstallClick = async () => {
    const permission = await requestNotificationPermission();
    console.log(permission);
    if (permission !== 'default') {
      if (!promptInstall) return;

      // Mostra o prompt de instalação
      promptInstall.prompt();
      // Decide o que fazer após o usuário responder ao prompt
      promptInstall.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {} else {
          console.log('Usuário recusou a instalação do PWA');
        }
        // Limpa o prompt pois não pode ser usado novamente
        setPromptInstall(null);
      });
    }
  };

  return (
    <InstallPWAContainer className='pwa'>
      {promptInstall && (
        <InstallPWAButton onClick={handleInstallClick} className="install-button">
          Instalar Aplicativo
        </InstallPWAButton>
      )}
    </InstallPWAContainer>
  );
};

export default InstallPWA;
