import styled from 'styled-components';

interface HubContainerProps {
  fontFamily?: string;
  fontColor?: string;
}

export const Background = styled.div`
  background-color: #fafafa;
`;

export const HubContainer = styled.div<HubContainerProps>`
  max-width: 1200px;
  display: block;
  margin: 0px auto;
  font-family: ${(props) => props.fontFamily || "'Arial', sans-serif"};
  color: ${(props) => props.fontColor || "black"};
  box-shadow: rgb(0 3 77 / 24%) 0px 2px 8px 0px;
`;

interface HeaderProps {
  backgroundColor: string;
}

export const Header = styled.header<HeaderProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 100%;
  background-color: ${(props) => props.backgroundColor || '#222'};
  gap: 8px;
  position: relative;

  a {
    transition: transform 0s cubic-bezier(0.23, 1, 0.32, 1) 0s;

    &:hover {
      transform: scale(1.075);
    }
  }
`;

export const SocialsButtons = styled.div`
  display: flex;
  gap: 12px;

  svg {
    width: 32px;
    height: 32px;
  }
`;

interface WhatsAppBarProps {
  backgroundColor?: string,
  buttonColor?: string,
  hoverColor?: string;
  textColor?: string
}

export const WhatsAppBar = styled.div<WhatsAppBarProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 68px;
  background-color: ${(props) => props.backgroundColor || '#fff'};
  color: ${(props) => props.textColor || '#000'};

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    position: absolute;
    border-radius: 8px;
    padding: 8px;
    background-color: ${(props) => props.buttonColor || 'rgb(1, 230, 117)'};
    cursor: pointer;

    svg {
      width: 24px;
      height: 24px;
    }

    &:hover {
      background-color: ${(props) => props.hoverColor || 'rgb(1, 230, 117, 0.7)'}; ;
    }
  }
`;

export const ImageOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
`;

interface ProfessionalDescriptionProps {
  backgroundImage?: string;
  backgroundColor?: string;
}

export const ProfessionalDescription = styled.div<ProfessionalDescriptionProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${(props) => (props.backgroundColor ? `${props.backgroundColor}` : 'none')};
  background-image: ${(props) => (props.backgroundImage ? `url(${props.backgroundImage})` : 'none')};
  background-size: cover; /* Faz com que a imagem cubra o contêiner mantendo sua proporção */
  background-position: center; /* Centraliza a imagem no contêiner */
  background-repeat: no-repeat; /* Evita repetir a imagem se o contêiner for maior */
  padding: 32px 0;

  img {
    background-color: rgba(var(--brd,var(--color_11)),var(--alpha-brd,1));
    border-color: rgba(var(--brd,var(--color_11)),var(--alpha-brd,1));
    border-radius: 50%;
    border-style: solid;
    border-width: var(--brw,2px);
    box-shadow: var(--shd,0 1px 3px rgba(0,0,0,0.5));
    display: block;
    width: 139px;
    height: 146px;
    z-index: 10;
  }

  h1, h2, h3 {
    z-index: 10;
    max-width: 500px;
  }

  h1 {
    margin-top: 16px;
    font-size: 32px;
    font-weight: 400;
  }

  h2 {
    margin-top: 8px;
    font-size: 24px;
    font-weight: 400;
  }

  h3 {
    font-size: 16px;
    font-weight: 400;   
  }
`;

interface SectionsProps {
  oddColor?: string;
  evenColor?: string;
}

export const Sections = styled.div<SectionsProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  section:nth-child(odd) {
    background-color: ${(props) => props.oddColor || '#fff'};
  }

  section:nth-child(even) {
    background-color: ${(props) => props.evenColor || '#eee'};
  }

  section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;   
    padding: 40px;
    width: 100%;

    .section-content {
      display: flex;
      justify-content: center;
      align-items: center;   
      flex-direction: column;
      gap: 40px;
      max-width: 800px;

      h1 {
        font-size: 32px;
        font-weight: 700;
        text-align: center;
      }

      h2 {
        text-align: center;
        font-size: 24px;
        font-weight: 700;
      }

      p {
        font-size: 16px;
        font-weight: 400;  
      }
    }
  }
`;

export const ContactContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 48px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 8px;

    width: 100%;
    
    input, textarea {
      width: 100%;
      padding: 8px;
    }

    button {
      height: 32px;
      background-color: #333;
      color: white;
      border: unset;
    }
  }
`;

export const PersonalInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  min-width: 292px;
  gap: 24px;
`;

export const Address = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  min-width: 292px;
  
  h2 {
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 8px;
  }
`;

export const Phone = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  min-width: 292px;
  
  h2 {
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 8px;
  }
`;

export const Email = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  min-width: 292px;
  
  h2 {
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 8px;
  }
`;

export const LinksContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  width: 100%;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }

  img {
    height: 100%; /* Mantém a altura da div */
    object-fit: cover; /* Mantém a proporção da imagem e cobre completamente a área */
    width: 70px;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border: 2px solid rgb(255, 255, 255);
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    box-shadow: rgb(0 3 77 / 24%) 0px 2px 8px 0px;
    transform: scale(1);
    transition: transform 0.25s cubic-bezier(0.08, 0.59, 0.29, 0.99) 0s, box-shadow 0.25s cubic-bezier(0.08, 0.59, 0.29, 0.99) 0s;
    border-radius: 8px;
    height: 60px;
  }

  a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    overflow-wrap: break-word;
    word-break: break-word;
    text-align: center;

    span {
      width: 100%;
    }
  }

  button {
    margin-right: 16px;
    height: 32px;
    width: 32px;
    background: none;
    border: none;

    svg {
      height: 16px;
      width: 16px;   
    }
  }
`;

export const ShareButtonContainer = styled.div`
  position: absolute;
  right: 0;
  top: 22px;

  svg {
    color: white !important;
    height: 24px !important;
    width: 24px !important;
  } 
`;