import React, { useState } from 'react';
import { ContactType, JsonPath, LinksHubSectionType, TextSectionType, YoutubeSectionType } from '../../../types/json-path';
import { replaceVars } from '../../../utils/replaceVars';
import { Email } from '../../screens/Hub/styles';
import ShareButton from '../ShareButton';
import { Address, CommercialTemplateLinkItem, CommercialTemplateLinksContainer, ContactContainer, LinkItem, PersonalInfo, Phone, SelfEmployedTemplate1LinksContainer, YoutubeEmbed } from './styles';

interface SectionsFactoryProps {
  section: LinksHubSectionType | TextSectionType | ContactType;
  json: JsonPath 
}

interface ContactFormData {
  name: string;
  email: string;
  subject: string;
  message: string;
}

const SectionsFactory: React.FC<SectionsFactoryProps> = ({ section, json }) => {
  const [formData, setFormData] = useState<ContactFormData>({ name: '', email: '', subject: '', message: '' });

  const handleInputFormChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const sendFormData = new FormData();

    sendFormData.append('from', `Contato de ${formData.name} <vbase@vbase.com.br>`);
    sendFormData.append('to', 'busamar@gmail.com');
    sendFormData.append('h:Reply-To', formData.email);
    sendFormData.append('subject', formData.subject);
    sendFormData.append('text', formData.message);

    const apiKey = '8ea2eb0369eaf3d3882c16a9a281e6fc-408f32f3-0893caed';
    const domain = 'vbase.com.br';
    const url = `https://api.mailgun.net/v3/${domain}/messages`;

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Authorization': 'Basic ' + btoa(`api:${apiKey}`)
        },
        body: sendFormData
      })
      if (!response.ok) {
        console.error('A solicitação falhou com o status ' + response.status);
        alert('Falha ao enviar o email.');
      }
      alert('Email enviado com sucesso!');
    } catch (e) {
      console.error('Falha ao enviar o email: ', e);
      alert('Falha ao enviar o email.');    
    }
  };

  const renderSection = () => {
    switch (section.type) {
      case 'links-hub': 
        const linksHubSection = section as LinksHubSectionType;
        if (json.template === 'CommercialTemplate') {
          return  (
            <section id={linksHubSection.anchor}>
              <div className='section-content'>
                <h1>{section.title}</h1>
                <CommercialTemplateLinksContainer>
                  {linksHubSection.links.map((link, index) => {
                    return (
                      <CommercialTemplateLinkItem key={index}>
                        <a href={link.url} target='_blank' rel='noreferrer'>
                          <img alt=''  src={link.image}></img>
                          <span>{link.text}</span>                                        
                        </a>
                        <a href={link.url} target='_blank' rel='noreferrer'>
                          <p>{link.description}</p>  
                        </a>                      
                        {link.isDisplayingShareButton && (
                          <ShareButton url={link.url} /> 
                        )} 
                      </CommercialTemplateLinkItem>
                    )
                  })}
                </CommercialTemplateLinksContainer>
              </div>
            </section>
          )
        }
        return (
          <section id={linksHubSection.anchor}>
            <div className='section-content'>
              <h1>{section.title}</h1>
              <SelfEmployedTemplate1LinksContainer>
                {linksHubSection.links.map((link, index) => {
                  return (
                    <LinkItem key={index}>
                      <a href={link.url} target='_blank' rel='noreferrer'>
                        <img alt=''  src={link.image}></img>
                        <span>{link.text}</span>                        
                      </a>
                      {link.isDisplayingShareButton && (
                        <ShareButton url={link.url} /> 
                      )} 
                    </LinkItem>
                  )
                })}
              </SelfEmployedTemplate1LinksContainer>
            </div>
          </section>
        )
      case 'text': 
        const textSection = section as TextSectionType;
        return (
          <section id={textSection.anchor}>
            <div className='section-content'>
              <h1>{textSection.title}</h1>
              {textSection.subtitle && <h2>{textSection.subtitle}​​</h2>}
              <p>{textSection.text}</p>
            </div>
          </section>
        )
      case 'youtube': 
        const youtubeSection = section as YoutubeSectionType;
        return (
          <section id={youtubeSection.anchor}>
            <div className='section-content'>
              <h1>{youtubeSection.title}</h1>
              {youtubeSection.subtitle && <h2>{youtubeSection.subtitle}​​</h2>}
              {youtubeSection.text && <p>{youtubeSection.text}</p>}
              <YoutubeEmbed 
                src={`https://www.youtube.com/embed/${youtubeSection.videoCode}`}
                title={youtubeSection.title} 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen />
            </div>
          </section>
        )  
      case 'contact': 
        const contactSection = section as ContactType;
        return (
          <section id={contactSection.anchor}>
            <div className='section-content'>
              <h1>{contactSection.title}</h1>
              <ContactContainer>
                <PersonalInfo>
                  <Address>   
                    {contactSection.address && (
                      <>
                        <h2>Endereço:</h2>       
                        <span>{contactSection.address.street}</span>
                        <span>{contactSection.address.complement}</span>
                        <span>{contactSection.address.neighborhoodAndCity}</span>
                      </>
                    )}
                  </Address>
                  <Phone>  
                    {contactSection.phone && (
                      <>
                        <h2>Telefone:</h2>       
                        <span>{replaceVars(json, contactSection.phone)}</span>
                      </>
                    )} 
                  </Phone>
                  <Email>
                      {contactSection.phone && (
                        <>
                          <h2>Email:</h2>       
                          <span>{replaceVars(json, contactSection.email)}</span>
                        </>
                      )}        
                  </Email>
                </PersonalInfo>
                {contactSection.form && contactSection.form.isDisplaying && (
                  <form id="contactForm" onSubmit={handleFormSubmit}>
                    <input type="text" name="name" placeholder='Nome *' value={formData.name} onChange={handleInputFormChange} />
                    <input type="email" name="email" placeholder='Email *' value={formData.email} onChange={handleInputFormChange} />
                    <input name="subject" placeholder='Assunto' value={formData.subject} onChange={handleInputFormChange} />
                    <textarea name="message" placeholder='Mensagem' value={formData.message} onChange={handleInputFormChange} />

                    <button type='submit'>Enviar</button>
                  </form>
                )}
              </ContactContainer>
            </div>
          </section>
        )  
      default:
        return (<div>Nenhuma sections encontrada para o tipo especificado</div>)
    }
  }

  return renderSection();
};

export default SectionsFactory;